import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'

const PageNotFound: React.FC = () => {
  return (
    <div className='PageNotFound content'>
      <p>
        Sorry, we couldn't find the page you were looking for.<br />
        <Link to='/'>Home</Link>.
      </p>
    </div>
  )
}

export default PageNotFound
