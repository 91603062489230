import React from 'react'
import './index.css'
import { FaTimes } from 'react-icons/fa'

type State = { open: boolean }
class UnderDevelopmentBanner extends React.Component<{}, State> {
  constructor (props: {}) {
    super(props)

    this.state = { open: true }
  }

  render () {
    return this.state.open
      ? (
        <div className='UnderDevelopmentBanner'>
          <div className='spread-and-vertically-centred'>
            <p>
              This site is currently under development. Some, many, or all of its
              features may be non-functional.
            </p>
            <FaTimes
              className='delete'
              onClick={() => this.setState({ open: false })}/>
          </div>
        </div>
      )
      : null
  }
}

export default UnderDevelopmentBanner
