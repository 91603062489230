import React from 'react'
import { firestore } from 'firebase' // eslint-disable-line no-unused-vars
import AreYouSureModal from '../AreYouSureModal'
import SweetrollsContext from '../../utilities/SweetrollsContext'

abstract class Deleter<
  TypesToDelete extends { ref: firestore.DocumentReference, name: string }[],
  Props,
  State extends {thingsToDelete: TypesToDelete | null}
> extends React.Component<Props, State> {
  abstract nameOfTypeOfThingToDelete: string

  static contextType = SweetrollsContext
  context!: React.ContextType<typeof SweetrollsContext>

  maybeDeleteThing (thingsToDelete: TypesToDelete) {
    this.setState({ thingsToDelete })
  }

  deleteThing (thingsToDelete: TypesToDelete) {
    this.setState({ thingsToDelete: null })
    thingsToDelete.forEach(
      ({ ref }) => ref.delete()
        .catch(error => this.context!.notificationsHandler.addNotification(
          error.message, 'error'
        ))
    )
  }

  getDeleteModal () {
    return (this.state.thingsToDelete &&
      <AreYouSureModal
        text={`
          Are you sure you want to delete the ${this.nameOfTypeOfThingToDelete}
          ${this.state.thingsToDelete[0].name}?
          This action is irreversible.
        `}
        yesAction={() => this.deleteThing(this.state.thingsToDelete!)}
        noAction={() => this.setState({ thingsToDelete: null })} />
    )
  }
}

export default Deleter
