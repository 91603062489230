import React from 'react'
import Firebase from './Firebase' // eslint-disable-line no-unused-vars
import { NotificationsHandler } from './notifications' // eslint-disable-line no-unused-vars

interface Context {
  firebase: Firebase,
  notificationsHandler: NotificationsHandler
}

const SweetrollsContext = React.createContext<Context | null>(null)

export default SweetrollsContext
