import React from 'react'
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, Link } from 'react-router-dom'
import './index.css'
// eslint-disable-next-line no-unused-vars
import { Unsubscribe } from 'firebase'
import Room from '../../models/Room' // eslint-disable-line no-unused-vars
import Button from '../../models/Button' // eslint-disable-line no-unused-vars
import Log from '../../models/Log' // eslint-disable-line no-unused-vars
import { Subscription } from 'rxjs' // eslint-disable-line no-unused-vars
import { FaPlus, FaTrash, FaEdit } from 'react-icons/fa'
import Deleter from '../Deleter'
import AddOrEditModal from '../AddOrEditModal'
import LoadingSpinner from '../LoadingSpinner'

type Props = RouteComponentProps<{uid: string, roomKey: string}>
type State = {
  room: Room | null,
  buttons: Button[] | null,
  logs: Log[] | null,
  roomOwnerUid: string,
  loggedInUserUid: string | null,
  error: string,
  thingsToDelete: [Button] | null,
  addingButton: boolean,
  waitingOnLogs: number,
  loading: boolean,
  buttonToEdit: Button | null
}
class RoomPage extends Deleter<[Button], Props, State> {
  nameOfTypeOfThingToDelete = 'button'

  private listener!: Unsubscribe
  roomSubscription!: Subscription
  buttonsSubscription!: Subscription
  logsSubscription!: Subscription

  roomPath?: string

  constructor (props: Props) {
    super(props)

    this.state = {
      room: null,
      buttons: null,
      logs: null,
      roomOwnerUid: this.props.match.params.uid,
      loggedInUserUid: null,
      error: '',
      thingsToDelete: null,
      addingButton: false,
      waitingOnLogs: 1,
      loading: true,
      buttonToEdit: null
    }
  }

  componentDidMount () {
    this.listener = this.context!.firebase.auth.onAuthStateChanged(authUser => {
      if (!authUser || authUser.uid !== this.state.roomOwnerUid) {
        this.props.history.push('/')
      }
      this.setState({ loggedInUserUid: authUser && authUser.uid })
    })

    const { getDocumentObservable, getCollectionObservable, firestore } =
      this.context!.firebase

    firestore
      .collection(`users/${this.state.roomOwnerUid}/roomKeys`)
      .doc(this.props.match.params.roomKey)
      .get()
      .then(documentSnapshot => {
        const { id } = documentSnapshot.data() as { id: string }
        this.roomPath = `users/${this.state.roomOwnerUid}/rooms/${id}`
        this.roomSubscription = getDocumentObservable<Room>(this.roomPath)
          .subscribe(room => {
            if (!room) return this.props.history.push('/')
            this.setState({ room })
          })
        this.buttonsSubscription = getCollectionObservable<Button>(
          this.roomPath + '/buttons'
        )
          .subscribe(buttons => this.setState({ buttons, loading: false }))
        this.logsSubscription = getCollectionObservable<Log>(
          this.roomPath + '/logs',
          logsCollection =>
            logsCollection.orderBy('timeCreated', 'desc').orderBy('result')
        )
          .subscribe(logs => {
            let numberOfLogsAdded = logs.length - (this.state.logs || []).length
            if (numberOfLogsAdded < 0) numberOfLogsAdded = 0
            let waitingOnLogs = this.state.waitingOnLogs - numberOfLogsAdded
            if (waitingOnLogs < 0) waitingOnLogs = 0
            if (logs.length === 0) waitingOnLogs = 0 // First emission is empty.
            this.setState({ logs, waitingOnLogs })
          })
      })
  }

  componentWillUnmount () { this.listener() }

  makeRoll (button: Button) {
    if (!this.roomPath) return
    const { firestore } = this.context!.firebase
    firestore.collection(this.roomPath + '/logs')
      .add({ expression: button.expression, uid: this.state.loggedInUserUid })
      .then(() => this.setState({ error: '' }))
      .catch(error => this.setState({ error: error.message }))
  }

  getAddButtonModal () {
    return this.state.room && this.state.addingButton && (
      <AddOrEditModal
        nameOfTypeOfThing='Button'
        fields={[
          { displayName: 'Name', realName: 'name' },
          { displayName: 'Expression', realName: 'expression' }
        ]}
        collectionPath={this.state.room.ref.path + '/buttons'}
        closeFunction={() => this.setState({ addingButton: false })}
        setLoading={loading => this.setState({ loading })}
      />
    )
  }

  getEditButtonModal () {
    return this.state.room && this.state.buttonToEdit && (
      <AddOrEditModal
        nameOfTypeOfThing='Button'
        fields={[
          {
            displayName: 'Name',
            realName: 'name',
            defaultValue: this.state.buttonToEdit.name
          },
          {
            displayName: 'Expression',
            realName: 'expression',
            defaultValue: this.state.buttonToEdit.expression
          }
        ]}
        collectionPath={this.state.room.ref.path + '/buttons'}
        closeFunction={() => this.setState({ buttonToEdit: null })}
        idToEdit={this.state.buttonToEdit.id}
      />
    )
  }

  render () {
    const buttons = (this.state.buttons || []).map(button => (
      <div key={button.id} className='spread-and-vertically-centred'>
        <div>
          <h4>{button.name}</h4>
          <p>{button.expression}</p>
        </div>
        <div className='spread-and-vertically-centred'>
          <button onClick={() => {
            this.setState({ waitingOnLogs: this.state.waitingOnLogs + 1 })
            this.makeRoll(button)
          }}>
            Roll
          </button>
          <FaEdit
            className='edit'
            onClick={() => this.setState({ buttonToEdit: button })}
          />
          <FaTrash
            className='delete'
            onClick={() => this.maybeDeleteThing([button])}
          />
        </div>
      </div>
    ))
    const logs = (this.state.logs || []).map(log => (
      <p className='log' key={log.id}>
        {log.uid} rolled {log.expression} getting
        <span className='result'> {log.result}</span>
      </p>
    ))
    return (
      <div className="RoomPage">
        {this.getDeleteModal()}
        {this.getAddButtonModal()}
        {this.getEditButtonModal()}
        <div className='spread-and-vertically-centred'>
          <h2>
            <Link to='/'>Your rooms</Link>
            {' > '}
            {this.state.room ? this.state.room.name : <LoadingSpinner small/>}
          </h2>
          <p className='error'>{this.state.error}</p>
        </div>
        <div className='room-container'>
          <div className='buttons'>
            <div className='spread-and-vertically-centred'>
              <h3>Room Buttons</h3>
              <FaPlus
                className='add'
                onClick={() => this.setState({ addingButton: true })}/>
            </div>
            {buttons}
            {this.state.loading && <div className='button-loader-container'>
              <LoadingSpinner />
            </div>}
          </div>
          <div className='logs'>
            { this.state.waitingOnLogs ? <LoadingSpinner small /> : ''}
            {logs}
          </div>
        </div>
      </div>
    )
  }
}

export default RoomPage
