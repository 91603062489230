import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import * as serviceWorker from './serviceWorker'

import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))

// Use register for a progressive web app. See
// https://facebook.github.io/create-react-app/docs/making-a-progressive-web-app
serviceWorker.unregister()
