// eslint-disable-next-line no-unused-vars
import React, { ChangeEvent, FormEvent } from 'react'
// eslint-disable-next-line no-unused-vars
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import LoggedOutOnly from '../LoggedOutOnly'
import checkEmailIsValid from '../../utilities/checkEmailIsValid'

type State = {
  email: string,
  password: string,
  formIsValid: Boolean,
  emailIsValid: Boolean,
  confirmEmailIsValid: Boolean,
  passwordIsValid: Boolean,
  error: string
}
class RegisterForm extends LoggedOutOnly<State> {
  constructor (props: RouteComponentProps) {
    super(props)

    this.state = {
      email: '',
      password: '',
      formIsValid: false,
      emailIsValid: false,
      confirmEmailIsValid: false,
      passwordIsValid: false,
      error: ''
    }
  }

  handleEmailFieldChange (event: ChangeEvent<HTMLInputElement>) {
    const email = event.target.value
    const emailIsValid = checkEmailIsValid(email)
    const formIsValid =
      emailIsValid &&
      this.state.confirmEmailIsValid &&
      this.state.passwordIsValid
    const error = emailIsValid ? '' : 'Invalid email'
    event.target.setCustomValidity(error)
    this.setState({ email, emailIsValid, formIsValid, error })
  }

  handleConfirmEmailFieldChange (event: ChangeEvent<HTMLInputElement>) {
    const confirmEmailIsValid = event.target.value === this.state.email
    const formIsValid =
      this.state.emailIsValid &&
      confirmEmailIsValid &&
      this.state.passwordIsValid
    const error = confirmEmailIsValid ? '' : 'Emails do not match'
    event.target.setCustomValidity(error)
    this.setState({ confirmEmailIsValid, formIsValid, error })
  }

  handlePasswordFieldChange (event: ChangeEvent<HTMLInputElement>) {
    const password = event.target.value
    const passwordIsValid = password.length >= 6
    const formIsValid =
      this.state.emailIsValid &&
      this.state.confirmEmailIsValid &&
      passwordIsValid
    const error =
      passwordIsValid ? '' : 'Password is not longer than five characters'
    event.target.setCustomValidity(error)
    this.setState(
      { password, passwordIsValid, formIsValid, error }
    )
  }

  handleSubmission (event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const { email, password } = this.state
    this.context!.firebase.register(email, password)
      .catch((error) => this.setState({ error: error.message }))
  }

  render () {
    return (
      <form
        className='full-size-form'
        onSubmit={this.handleSubmission.bind(this)}>
        <h1>Register</h1>
        <p>
          If you already have an account you can
          login <Link to='/login'>here</Link>.<br />
          Your password must be six or more characters.
        </p>
        <input
          className='white-text'
          type='email'
          placeholder='Email'
          onChange={this.handleEmailFieldChange.bind(this)} />
        <input
          className='white-text'
          type='email'
          placeholder='Confirm email'
          onChange={this.handleConfirmEmailFieldChange.bind(this)} />
        <input
          className='white-text'
          type='password'
          placeholder='Password'
          onChange={this.handlePasswordFieldChange.bind(this)} />
        <p className='error'>{this.state.error}</p>
        <button type='submit' disabled={!this.state.formIsValid}>
          Register
        </button>
      </form>
    )
  }
}

export default withRouter(RegisterForm)
