import React from 'react'
// eslint-disable-next-line no-unused-vars
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { Subscription } from 'rxjs' // eslint-disable-line no-unused-vars
import { FaTrash, FaPlus, FaEdit } from 'react-icons/fa'
import { firestore } from 'firebase' // eslint-disable-line no-unused-vars
import './index.css'

import Room from '../../models/Room' // eslint-disable-line no-unused-vars
import Deleter from '../Deleter'
import AddOrEditModal from '../AddOrEditModal'
import LoadingSpinner from '../LoadingSpinner'

const ROOM_KEY_REGEX_REQUIREMENT = /^[a-zA-Z0-9-_]+$/

type State = {
  rooms: Room[],
  thingsToDelete:
    [Room, { ref: firestore.DocumentReference, name: string }] | null,
  error: string,
  addingRoom: boolean,
  loading: boolean,
  roomToEdit: Room | null
}
type Props = RouteComponentProps & { uid: string }
class Rooms extends Deleter<
  [Room, { ref: firestore.DocumentReference, name: string }], Props, State
> {
  nameOfTypeOfThingToDelete = 'room'
  roomSubscription!: Subscription

  private modalFields = {
    name: { displayName: 'Name', realName: 'name' },
    key: {
      displayName: 'Unique Key',
      realName: 'key',
      regexRequirement: ROOM_KEY_REGEX_REQUIREMENT,
      description: 'A unique key for the room URL made of characters ' +
        'that are alphanumeric, dashes, or underscores'
    }
  }
  private preAddOrEditHook = async (
    { key }: { [key: string]: string }, { id }: firestore.DocumentReference
  ) => {
    if (!ROOM_KEY_REGEX_REQUIREMENT.test(key)) {
      throw new Error('Invalid key')
    }

    const roomKeysCollection = this.context!.firebase.firestore
      .collection(`users/${this.props.uid}/roomKeys`)
    const roomKeyReference = roomKeysCollection.doc(key)

    // Make sure this key isn't already taken.
    const documentSnapshot = await roomKeyReference.get()
    if (documentSnapshot.exists) {
      throw new Error('Room key is already in use')
    }

    // Delete any existing roomKeys for this room
    const query = await roomKeysCollection.where('id', '==', id).get()
    const promises: Promise<void>[] = []
    query.forEach(queryDocumentSnapshot => {
      promises.push(queryDocumentSnapshot.ref.delete())
    })
    await Promise.all(promises)

    // Add the new room key.
    return roomKeyReference.set({ id }, { merge: true })
  }

  constructor (props: Props) {
    super(props)
    this.state = {
      rooms: [],
      thingsToDelete: null,
      error: '',
      addingRoom: false,
      loading: false,
      roomToEdit: null
    }
  }

  componentDidMount () {
    const { getCollectionObservable } = this.context!.firebase

    const roomObservable =
      getCollectionObservable<Room>(`users/${this.props.uid}/rooms`)
    this.roomSubscription = roomObservable.subscribe(rooms => {
      this.setState({ rooms, loading: false })
    })
  }

  componentWillUnmount () {
    this.roomSubscription.unsubscribe()
  }

  getAddRoomModal () {
    return this.state.addingRoom && (
      <AddOrEditModal
        nameOfTypeOfThing='Room'
        fields={[this.modalFields.name, this.modalFields.key]}
        collectionPath={`users/${this.props.uid}/rooms`}
        closeFunction={() => this.setState({ addingRoom: false })}
        setLoading={(loading: boolean) => this.setState({ loading })}
        preSubmissionHook={this.preAddOrEditHook}
      />
    )
  }

  getEditRoomModal () {
    return this.state.roomToEdit && (
      <AddOrEditModal
        nameOfTypeOfThing='Room'
        fields={[
          {
            ...this.modalFields.name, defaultValue: this.state.roomToEdit.name
          },
          { ...this.modalFields.key, defaultValue: this.state.roomToEdit.key }
        ]}
        collectionPath={`users/${this.props.uid}/rooms`}
        closeFunction={() => this.setState({ roomToEdit: null })}
        preSubmissionHook={this.preAddOrEditHook}
        idToEdit={this.state.roomToEdit.id}
      />
    )
  }

  render () {
    const rooms = this.state.rooms.map(room =>
      <div key={room.id} className='room-tile'>
        <div>
          <h2>{room.name}<i> {room.key}</i></h2>
          <Link
            to={`/${this.props.uid}/${room.key}`}
            className='room-link'>
            Enter
          </Link>
        </div>
        <div>
          <FaEdit
            className='edit'
            onClick={() => this.setState({ roomToEdit: room })}
          />
          <FaTrash
            className='delete'
            onClick={() => this.maybeDeleteThing([
              room,
              {
                ref: this.context!.firebase.firestore
                  .collection(`users/${this.props.uid}/roomKeys`)
                  .doc(room.key),
                name: room.key
              }
            ])}/>
        </div>
      </div>
    )

    return (
      <div className='Rooms'>
        {this.getDeleteModal()}
        {this.getAddRoomModal()}
        {this.getEditRoomModal()}
        <div className='your-rooms-header'>
          <h1>Your Rooms</h1>
          <p className='error'>{this.state.error}</p>
          <FaPlus
            className='add'
            onClick={() => this.setState({ addingRoom: true })} />
        </div>
        {
          <div className='rooms-container'>
            {rooms}
            {this.state.loading &&
              <div className='room-tile loading-room'>
                <LoadingSpinner />
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

export default withRouter(Rooms)
