type NotificationType = 'success' | 'error'
export interface Notification {
  content: string,
  type: NotificationType
}

// An array of notifications with ids. These ids increase, but cannot just be
// index as notifications may be removed. The ids must not be changed outside of
// this code.
export type NotificationCollection = {id: number, notification: Notification}[]
export class NotificationsHandler {
  private getNotifications: () => NotificationCollection
  private setNotifications: (notifications: NotificationCollection) => void
  private appendNotification: (notification: Notification) => void

  constructor (
    getNotifications: () => NotificationCollection,
    setNotifications: (notifications: NotificationCollection) => void,
    appendNotification: (notification: Notification) => void
  ) {
    this.getNotifications = getNotifications
    this.setNotifications = setNotifications
    this.appendNotification = appendNotification
  }

  addNotification (content: string, type: NotificationType) {
    this.appendNotification({ content, type })
  }

  removeNotification (idToRemove: number) {
    this.setNotifications(
      this.getNotifications().filter(({ id }) => id !== idToRemove)
    )
  }

  clearAll () { this.setNotifications([]) }
}
