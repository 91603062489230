import React from 'react'
// eslint-disable-next-line no-unused-vars
import SweetrollsContext from '../../utilities/SweetrollsContext'

class LogoutButton extends React.Component {
  static contextType = SweetrollsContext
  context!: React.ContextType<typeof SweetrollsContext>

  render () {
    return (
      <button
        type="button"
        onClick={
          this.context
            ? this.context.firebase.logout.bind(this.context)
            : () => {}
        }
        disabled={!this.context}>
        Logout
      </button>
    )
  }
}

export default LogoutButton
