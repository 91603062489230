import 'firebase/auth'
import 'firebase/firestore'
import firebase from 'firebase/app'
import { collection, doc } from 'rxfire/firestore'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs' // eslint-disable-line no-unused-vars

const config = {
  apiKey: 'AIzaSyA54LxyRUFXCmyaL8Z0DJSTaS4E6qBrkOc',
  authDomain: 'sweetrolls-app.firebaseapp.com',
  databaseURL: 'https://sweetrolls-app.firebaseio.com',
  projectId: 'sweetrolls-app',
  storageBucket: 'sweetrolls-app.appspot.com',
  messagingSenderId: '901239112222',
  appId: '1:901239112222:web:65144615ade89046'
}

class Firebase {
  app: firebase.app.App
  auth: firebase.auth.Auth
  firestore: firebase.firestore.Firestore

  constructor () {
    this.app = firebase.initializeApp(config)
    this.firestore = firebase.firestore(this.app)
    this.auth = firebase.auth(this.app)
  }

  public getDocumentObservable = <T extends {
    id: string,
    ref: firebase.firestore.DocumentReference
  }>(path: string): Observable<T | null> => {
    const documentReference = this.firestore.doc(path)
    return doc(this.firestore.doc(path)).pipe(
      map((documentSnapshot) => {
        if (!documentSnapshot.exists) return null
        const id = documentSnapshot.id
        const data = documentSnapshot.data()
        return { id, ref: documentReference, ...data } as T
      })
    )
  }

  public getCollectionObservable = <T extends {
    id: string,
    ref: firebase.firestore.DocumentReference
  }>(
    path: string,
    querier?: (collectionReference: firebase.firestore.CollectionReference) =>
      firebase.firestore.Query
  ): Observable<T[]> => {
    const collectionReference = this.firestore.collection(path)
    const query = querier ? querier(collectionReference) : collectionReference
    return collection(query).pipe(
      map((queryDocumentSnapshots) => queryDocumentSnapshots.map(
        queryDocumentSnapshot => ({
          id: queryDocumentSnapshot.id,
          ref: queryDocumentSnapshot.ref,
          ...queryDocumentSnapshot.data()
        } as T)
      ))
    )
  }

  public register (email: string, password: string) {
    return this.auth.createUserWithEmailAndPassword(email, password)
  }

  public login (email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password)
  }

  public logout () {
    return this.auth.signOut()
  }

  public resetPassword (email: string) {
    return this.auth.sendPasswordResetEmail(email)
  }

  public updatePassword (password: string) {
    if (!this.auth.currentUser) {
      return Promise.reject(new Error('No logged in user'))
    }
    return this.auth.currentUser.updatePassword(password)
  }
}

export default Firebase
