// eslint-disable-next-line no-unused-vars
import React, { ChangeEvent, FormEvent } from 'react'
import checkEmailIsValid from '../../utilities/checkEmailIsValid'
import SweetrollsContext from '../../utilities/SweetrollsContext'

type State = {
  email: string, emailIsValid: boolean, message: string, messageIsError: boolean
}
class ResetPasswordForm extends React.Component<{}, State> {
  static contextType = SweetrollsContext
  context!: React.ContextType<typeof SweetrollsContext>

  constructor (props: {}) {
    super(props)

    this.state = {
      email: '', emailIsValid: false, message: '', messageIsError: true
    }
  }

  handleEmailFieldChange (event: ChangeEvent<HTMLInputElement>) {
    const email = event.target.value
    const emailIsValid = checkEmailIsValid(email)
    const message = emailIsValid ? '' : 'Invalid email'
    event.target.setCustomValidity(message)
    this.setState(
      { email, emailIsValid, message, messageIsError: !emailIsValid }
    )
  }

  handleSubmission (event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const { email } = this.state
    this.context!.firebase.resetPassword(email)
      .then(() => this.setState({
        message: 'If the account existed, the reset email has been sent.',
        emailIsValid: false,
        messageIsError: false
      }))
      .catch((error) => this.setState(
        { message: error.message, messageIsError: true }
      ))
  }

  render () {
    return (
      <form onSubmit={this.handleSubmission.bind(this)} className="full-size-form">
        <h1>Reset Password</h1>
        <p>
          Enter an email below, and if there is an account associated with it,
          we'll send it a password reset link.
        </p>
        <input
          type='email'
          className='white-text'
          placeholder='Email'
          onChange={this.handleEmailFieldChange.bind(this)} />
        <p
          className='error'
          style={{ color: this.state.messageIsError ? 'red' : 'white' }}>
          {this.state.message}
        </p>
        <button type='submit' disabled={!this.state.emailIsValid}>
          Submit
        </button>
      </form>
    )
  }
}

export default ResetPasswordForm
