import React from 'react'
import Modal from '../Modal'

type Props = { text: string, yesAction: () => void, noAction: () => void }
class AreYouSureModal extends React.Component<Props> {
  render () {
    return (
      <Modal closeFunction={this.props.noAction}>
        {this.props.text}
        <div className='buttons-row'>
          <button onClick={this.props.yesAction}>Yes</button>
          <button onClick={this.props.noAction}>No</button>
        </div>
      </Modal>
    )
  }
}

export default AreYouSureModal
