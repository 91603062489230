import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { User } from 'firebase' // eslint-disable-line no-unused-vars
import Firebase from './utilities/Firebase'
import {
  NotificationsHandler,
  NotificationCollection // eslint-disable-line no-unused-vars
} from './utilities/notifications'
import SweetrollsContext from './utilities/SweetrollsContext'

import './App.css'

import Footer from './components/Footer'
import Welcome from './components/Welcome'
import Header from './components/Header'
import LoginForm from './components/LoginForm'
import RegisterForm from './components/RegisterForm'
import PageNotFound from './components/PageNotFound'
import Rooms from './components/Rooms'
import UnderDevelopmentBanner from './components/UnderDevelopmentBanner'
import ResetPasswordForm from './components/ResetPasswordForm'
import RoomPage from './components/RoomPage'
import LoadingSpinner from './components/LoadingSpinner'
import NotificationsComponent from './components/NotificationsComponent'

type State = {
  authUser: User | null,
  userHasBeenChecked: boolean,
  notifications: NotificationCollection
}
class App extends React.Component<{}, State> {
  private firebase: Firebase
  private notificationsHandler: NotificationsHandler

  constructor (props: {}) {
    super(props)

    this.firebase = new Firebase()
    this.notificationsHandler = new NotificationsHandler(
      () => this.state.notifications,
      notifications => this.setState({ notifications }),
      notification => {
        // Use the setState callback to avoid race conditions.
        this.setState(({ notifications }) => ({
          // Get the notifications, but re-id them by index so we don't have to
          // manually find the maximum id.
          notifications: notifications
            .map(({ notification }, index) => ({ id: index, notification }))
            .concat({ id: notifications.length, notification })
        }))
      }
    )
    this.state = {
      authUser: null,
      userHasBeenChecked: false,
      notifications: []
    }
  }

  componentDidMount () {
    this.firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState({ authUser, userHasBeenChecked: true })
        : this.setState({ authUser: null, userHasBeenChecked: true })
    })
  }

  render () {
    const homePage = this.state.authUser
      ? (<Rooms uid={this.state.authUser.uid} />)
      : (<Welcome />)

    return (
      <SweetrollsContext.Provider value={
        { firebase: this.firebase, notificationsHandler: this.notificationsHandler }
      }>
        <Router>
          <UnderDevelopmentBanner />
          <div className='app'>
            <Header
              authUser={this.state.authUser}
              userHasBeenChecked={this.state.userHasBeenChecked}
            />
            <div className='content'>
              <NotificationsComponent
                notifications={this.state.notifications}
                notificationsHandler={this.notificationsHandler}
              />
              <Switch>
                <Route path='/' exact>
                  {
                    this.state.userHasBeenChecked
                      ? homePage
                      : <div className="content spread-and-vertically-centred">
                        <LoadingSpinner />
                      </div>
                  }
                </Route>
                <Route path='/login' exact component={LoginForm} />
                <Route path='/register' exact component={RegisterForm} />
                <Route
                  path='/reset-password'
                  exact
                  component={ResetPasswordForm} />
                <Route path='/:uid/:roomKey' exact component={RoomPage} />
                <Route component={PageNotFound} />
              </Switch>
            </div>
            <Footer />
          </div>
        </Router>
      </SweetrollsContext.Provider>
    )
  }
}

export default App
