import React from 'react'
import './index.css'
import { FaTimes } from 'react-icons/fa'

import {
  NotificationCollection, // eslint-disable-line no-unused-vars
  NotificationsHandler // eslint-disable-line no-unused-vars
} from '../../utilities/notifications'

type Props = {
  notifications: NotificationCollection,
  notificationsHandler: NotificationsHandler
}
const NotificationsComponent: React.FC<Props> = (props: Props) => {
  const notifications = props.notifications.map(
    ({ id, notification }) => <div
      className={`
        notification  ${notification.type}-notification
        spread-and-vertically-centred
      `}
      id={'' + id}
    >
      <div>{notification.content}</div>
      <FaTimes
        className='notification-closer'
        onClick={() => props.notificationsHandler.removeNotification(id)}/>
    </div>
  )

  return <div className="NotificationsComponent">
    {notifications}
  </div>
}

export default NotificationsComponent
