// eslint-disable-next-line no-unused-vars
import React, { ChangeEvent, FormEvent } from 'react'
// eslint-disable-next-line no-unused-vars
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import LoggedOutOnly from '../LoggedOutOnly'
import checkEmailIsValid from '../../utilities/checkEmailIsValid'

type State = {
  email: string,
  password: string,
  formIsValid: Boolean,
  emailIsValid: Boolean,
  passwordIsValid: Boolean,
  error: string
}
class LoginForm extends LoggedOutOnly<State> {
  constructor (props: RouteComponentProps) {
    super(props)

    this.state = {
      email: '',
      password: '',
      formIsValid: false,
      emailIsValid: false,
      passwordIsValid: false,
      error: ''
    }
  }

  handleEmailFieldChange (event: ChangeEvent<HTMLInputElement>) {
    const email = event.target.value
    const emailIsValid = checkEmailIsValid(email)
    const formIsValid = emailIsValid && this.state.passwordIsValid
    const error = emailIsValid ? '' : 'Invalid email'
    event.target.setCustomValidity(error)
    this.setState({ email, emailIsValid, formIsValid, error })
  }

  handlePasswordFieldChange (event: ChangeEvent<HTMLInputElement>) {
    const password = event.target.value
    const passwordIsValid = password.length >= 6
    const formIsValid = this.state.emailIsValid && passwordIsValid
    const error =
      passwordIsValid ? '' : 'Password is not longer than five characters'
    event.target.setCustomValidity(error)
    this.setState(
      { password, passwordIsValid, formIsValid, error }
    )
  }

  handleSubmission (event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const { email, password } = this.state
    this.context!.firebase.login(email, password)
      .catch((error) => this.setState({ error: error.message }))
  }

  render () {
    return (
      <form
        className='full-size-form'
        onSubmit={this.handleSubmission.bind(this)}>
        <h1>Login</h1>
        <p>
          If you don't have an account yet, you can
          register <Link to='/register'>here</Link>.
        </p>
        <input
          className='white-text'
          type='email'
          placeholder='Email'
          onChange={this.handleEmailFieldChange.bind(this)} />
        <input
          className='white-text'
          type='password'
          placeholder='Password'
          onChange={this.handlePasswordFieldChange.bind(this)} />
        <Link to='/reset-password'>Forgotten your password?</Link>
        <p className='error'>{this.state.error}</p>
        <button type='submit' disabled={!this.state.formIsValid}>
          Login
        </button>
      </form>
    )
  }
}

export default withRouter(LoginForm)
