import React, { MouseEvent } from 'react' // eslint-disable-line no-unused-vars
import './index.css'

type Props = { closeFunction: () => void, children: any }
class AreYouSureModal extends React.Component<Props> {
  handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 27) this.props.closeFunction()
  }

  componentWillMount () {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  render () {
    const handleCoverClick = (event: MouseEvent<HTMLDivElement>) => {
      // event.target is the div it was on, even if it thinks it isn't.
      if ((event.target as HTMLDivElement).classList.contains('Modal')) {
        this.props.closeFunction()
      }
    }

    return (
      <div className='Modal' onClick={handleCoverClick}>
        <div className='popup'>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default AreYouSureModal
