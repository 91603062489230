import React from 'react'
import { Link } from 'react-router-dom'
import './index.css'

import { User } from 'firebase' // eslint-disable-line no-unused-vars

import LogoutButton from '../LogoutButton'
import LoadingSpinner from '../LoadingSpinner'

type Props = { authUser: User | null, userHasBeenChecked: boolean }
const Header: React.FC<Props> = (props: Props) => {
  const userSection = props.authUser
    ? <div><LogoutButton /></div>
    : <div>
      <Link to='/login'>Login</Link> / <Link to='/register'>Register</Link>
    </div>

  return (
    <div className='Header'>
      <Link className='title' to='/'>sweetrolls</Link>
      { props.userHasBeenChecked ? userSection : <div><LoadingSpinner small /></div> }
    </div>
  )
}

export default Header
