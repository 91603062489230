// eslint-disable-next-line no-unused-vars
import React, { ChangeEvent, FormEvent } from 'react'
import { firestore } from 'firebase' // eslint-disable-line no-unused-vars

import Modal from '../Modal'
import SweetrollsContext from '../../utilities/SweetrollsContext'

type Field = {
  displayName: string,
  realName: string,
  defaultValue?: string,
  regexRequirement?: RegExp,
  description?: string,
  required?: boolean
}
type Props = {
  nameOfTypeOfThing: string,
  fields: Field[],
  collectionPath: string,
  closeFunction: () => void,
  setLoading?: (loading: boolean) => void,
  idToEdit?: string,
  preSubmissionHook?: (
    thingToAdd: {[key: string]: string}, reference: firestore.DocumentReference
  ) => Promise<any>
}
type State = {
  thingToAdd: {[key: string]: string},
  showing: boolean
}
class AddOrEditModal extends React.Component<Props, State> {
  static contextType = SweetrollsContext
  context!: React.ContextType<typeof SweetrollsContext>

  constructor (props: Props) {
    super(props)

    const thingToAdd: {[key: string]: string} = {}
    for (const field of this.props.fields) {
      thingToAdd[field.realName] = field.defaultValue || ''
    }
    this.state = { thingToAdd, showing: true }
  }

  handleFieldChange (event: ChangeEvent<HTMLInputElement>, field: Field) {
    const newThingToAdd = Object.assign({}, this.state.thingToAdd)
    newThingToAdd[field.realName] = event.target.value
    this.setState({ thingToAdd: newThingToAdd })
  }

  handleSubmission = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { firestore } = this.context!.firebase
    this.setState({ showing: false })
    if (this.props.setLoading) this.props.setLoading(true)

    const reference = this.props.idToEdit
      ? firestore.collection(this.props.collectionPath).doc(this.props.idToEdit)
      : firestore.collection(this.props.collectionPath).doc()
    const preSubmissionHook =
      this.props.preSubmissionHook || (() => Promise.resolve())
    preSubmissionHook(this.state.thingToAdd, reference)
      .then(() => reference.set(this.state.thingToAdd, { merge: true }))
      .catch((error: { message: string }) => {
        this.context!.notificationsHandler
          .addNotification(error.message, 'error')
      })
      .then(() => {
        if (this.props.setLoading) this.props.setLoading(false)
        this.props.closeFunction()
      })
  }

  render () {
    const fields = this.props.fields.map(field => {
      return (
        <input key={field.realName}
          className='black-text'
          type='text'
          onChange={(event) => this.handleFieldChange(event, field)}
          placeholder={field.displayName}
          title={field.description}
          value={this.state.thingToAdd[field.realName]}
          required
          pattern={
            field.regexRequirement ? field.regexRequirement.source : '.*'
          }
        />
      )
    })
    const action = this.props.idToEdit ? 'Edit' : 'Add'

    return (
      this.state.showing && <Modal closeFunction={this.props.closeFunction}>
        <form onSubmit={this.handleSubmission}>
          <h1 className='black-text'>
            {action} {this.props.nameOfTypeOfThing}
          </h1>
          {fields}
          <div className='buttons-row'>
            <button type='submit'>{action}</button>
            <button onClick={(this.props.closeFunction)}>Cancel</button>
          </div>
        </form>
      </Modal>
    )
  }
}

export default AddOrEditModal
