// eslint-disable-next-line no-unused-vars
import React from 'react'
// eslint-disable-next-line no-unused-vars
import { Unsubscribe } from 'firebase'
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps } from 'react-router-dom'
// eslint-disable-next-line no-unused-vars
import SweetrollsContext from '../../utilities/SweetrollsContext'

class LoggedOutOnly<State> extends React.Component<RouteComponentProps, State> {
  private listener!: Unsubscribe
  static contextType = SweetrollsContext
  context!: React.ContextType<typeof SweetrollsContext>

  componentDidMount () {
    this.listener = this.context!.firebase.auth.onAuthStateChanged(
      authUser => authUser ? this.props.history.push('/') : 0
    )
  }

  componentWillUnmount () { this.listener() }
}

export default LoggedOutOnly
