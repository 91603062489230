import React from 'react'
import './index.css'

const Footer: React.FC = () => {
  return (
    <div className="Footer">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.thewrongjames.com"
      >
        Made, with <span role="img" aria-label="love">❤️</span>, by James Wright
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/thewrongjames"
      >
        @thewrongjames
      </a>
    </div>
  )
}

export default Footer
