import React from 'react'
import { Link } from 'react-router-dom'
import './index.css'

const Welcome: React.FC = () => {
  return (
    <div className='Welcome content'>
      <p>
        Welcome to sweetrolls, a web app for rolling dice with friends.<br />
        To join a room and start rolling please<br />
        <Link to='/login'>Login</Link> or <Link to='/register'>Register</Link>.
      </p>
    </div>
  )
}

export default Welcome
